// NOTE: This is generated code and should NOT be edited for any reason.
// If an error occurs with the generated code, submit a PR into 
// https://github.com/MSDOStoevsky/tag-api-codegen, or alter the 
// project locally if forked.
/* eslint-disable  @typescript-eslint/no-empty-interface */

/**
 * 
 */
export interface PostTokenResponse {
    /**
     * 
     */
    access_token?: string;
    /**
     * 
     */
    expires_in?: number;
    /**
     * 
     */
    token_type?: string;
    /**
     * 
     */
    refresh_token?: string;
}

/**
 * 
 */
export interface RefreshTokenResponse {
    /**
     * 
     */
    access_token: string;
    /**
     * 
     */
    expires_in?: number;
    /**
     * 
     */
    token_type?: string;
}

/**
 * 
 */
export interface GetVinLastKnownResponse {
    /**
     * 
     */
    Items: Array<VinLastKnown>;
    /**
     * 
     */
    Count?: number;
    /**
     * 
     */
    ScannedCount?: number;
    /**
     * 
     */
    LastEvaluatedKey?: Record<string, unknown>;
}

/**
 * 
 */
export interface GetVinDataResponse {
    /**
     * 
     */
    Items: Array<VinData>;
    /**
     * 
     */
    LastEvaluatedKey?: Record<string, unknown>;
}

/**
 * 
 */
export interface VinData {
    /**
     * 
     */
    vin?: string;
    /**
     * 
     */
    policy?: number;
    /**
     * 
     */
    app_timestamp?: string;
    /**
     * 
     */
    data: Record<string, unknown>;
    /**
     * 
     */
    hash?: string;
    /**
     * 
     */
    plan?: number;
}

/**
 * 
 */
export interface VinLastKnown {
    /**
     * 
     */
    vin: string;
    /**
     * 
     */
    value: string;
    /**
     * 
     */
    signal: string;
    /**
     * 
     */
    timestamp: string;
}

/**
 * 
 */
export interface GetHistogramsResponse {
    /**
     * 
     */
    Items: Array<Histogram>;
    /**
     * 
     */
    Count?: number;
    /**
     * 
     */
    ScannedCount?: number;
}

/**
 * 
 */
export interface GetSensorsResponse {
    /**
     * 
     */
    Items: Array<Sensor>;
}

/**
 * 
 */
export interface GetIamPoliciesReponse {
    /**
     * 
     */
    data: Array<IamPolicy>;
}

/**
 * 
 */
export interface IamPolicy {
    /**
     * 
     */
    PolicyName?: string;
    /**
     * 
     */
    PolicyId?: string;
    /**
     * 
     */
    Arn?: string;
    /**
     * 
     */
    Path?: string;
    /**
     * 
     */
    DefaultVersionId?: string;
    /**
     * 
     */
    AttachmentCount?: number;
    /**
     * 
     */
    PermissionsBoundaryUsageCount?: number;
    /**
     * 
     */
    IsAttachable?: boolean;
    /**
     * 
     */
    CreateDate?: string;
    /**
     * 
     */
    UpdateDate?: string;
}

/**
 * 
 */
export interface UserSearchResponse {
    /**
     * 
     */
    data: Array<User>;
    /**
     * 
     */
    pageInfo: PageInfo;
}

/**
 * 
 */
export interface VinSearchResponse {
    /**
     * 
     */
    data: Array<VinProfile>;
    /**
     * 
     */
    pageInfo: PageInfo;
}

/**
 * 
 */
export interface PolicySearchResponse {
    /**
     * 
     */
    data: Array<PolicyResponse>;
    /**
     * 
     */
    pageInfo: PageInfo;
}

/**
 * 
 */
export interface PolicyResponse {
    /**
     * 
     */
    grp_id: number;
    /**
     * 
     */
    pname: string;
    /**
     * 
     */
    renew: number;
    /**
     * 
     */
    plans: Array<AdaProtobufPlan | AdaJsonPollEventPlan | AdaJsonPopupPlan>;
    /**
     * 
     */
    type: any;
    /**
     * 
     */
    createdByUser?: string;
    /**
     * 
     */
    backup_window: number;
    /**
     * 
     */
    delay: number;
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    timestamp?: number;
    /**
     * 
     */
    updated_timestamp?: number;
    /**
     * 
     */
    fname?: string;
    /**
     * 
     */
    signature?: string;
    /**
     * 
     */
    signedEnv?: string;
    /**
     * 
     */
    compressedPolicy?: string;
}

/**
 * 
 */
export interface UpdatePolicyRequest {
}

/**
 * 
 */
export interface Sensor {
    /**
     * 
     */
    servicetype?: string;
    /**
     * 
     */
    maxbuf?: string;
    /**
     * 
     */
    resoluions?: string;
    /**
     * 
     */
    dataTypes?: string;
    /**
     * 
     */
    prop?: Array<string>;
    /**
     * 
     */
    source: SensorType;
    /**
     * 
     */
    conditionpush?: string;
    /**
     * 
     */
    isAvailable?: string;
    /**
     * 
     */
    descriptn?: string;
    /**
     * 
     */
    state?: Array<string>;
    /**
     * 
     */
    avglen?: string;
    /**
     * 
     */
    ppsSource?: string;
    /**
     * 
     */
    channelNames?: string;
    /**
     * 
     */
    minabsranges?: string;
    /**
     * 
     */
    componentname: string;
    /**
     * 
     */
    contextType?: string;
    /**
     * 
     */
    iswritable?: string;
    /**
     * 
     */
    ppsattributes?: string;
    /**
     * 
     */
    channelNos?: string;
    /**
     * 
     */
    maxabsranges?: string;
    /**
     * 
     */
    version?: string;
    /**
     * 
     */
    type?: string;
}

/**
 * 
 */
export interface Role {
    /**
     * 
     */
    Path?: string;
    /**
     * 
     */
    RoleName?: string;
    /**
     * 
     */
    RoleId?: string;
    /**
     * 
     */
    Arn?: string;
    /**
     * 
     */
    CreateDate?: string;
    /**
     * 
     */
    AssumeRolePolicyDocument?: string;
    /**
     * 
     */
    Tags?: Array<string>;
}

/**
 * 
 */
export interface User {
    /**
     * 
     */
    oid?: string;
    /**
     * 
     */
    cognito_username?: string;
    /**
     * 
     */
    given_name?: string;
    /**
     * 
     */
    family_name?: string;
    /**
     * 
     */
    email?: string;
    /**
     * 
     */
    is_email_verified?: boolean;
    /**
     * 
     */
    created_timestamp?: string;
    /**
     * 
     */
    updated_timestamp?: string;
    /**
     * 
     */
    is_user_enabled?: boolean;
    /**
     * 
     */
    user_status?: string;
}

/**
 * 
 */
export interface Me {
    /**
     * 
     */
    oid?: string;
    /**
     * 
     */
    cognito_username?: string;
    /**
     * 
     */
    given_name?: string;
    /**
     * 
     */
    family_name?: string;
    /**
     * 
     */
    email?: string;
    /**
     * 
     */
    is_email_verified?: boolean;
    /**
     * 
     */
    created_timestamp?: string;
    /**
     * 
     */
    updated_timestamp?: string;
    /**
     * 
     */
    is_user_enabled?: boolean;
    /**
     * 
     */
    user_status?: string;
    /**
     * 
     */
    groups?: Array<string>;
    /**
     * 
     */
    operations?: Array<UserPermissions>;
}

/**
 * 
 */
export interface Group {
    /**
     * 
     */
    name: string;
    /**
     * 
     */
    description?: string;
    /**
     * 
     */
    operations?: Array<UserPermissions>;
}

/**
 * 
 */
export interface SendLivePolicyRequest {
    /**
     * 
     */
    vins: Array<any>;
    /**
     * 
     */
    plan: AdaProtobufPlan;
}

/**
 * 
 */
export interface CreateGroupRequest {
    /**
     * 
     */
    groupName: string;
    /**
     * 
     */
    operations: Array<UserPermissions>;
}

/**
 * 
 */
export interface CreateUserRequest {
    /**
     * 
     */
    userName: string;
    /**
     * 
     */
    email: string;
    /**
     * 
     */
    temporaryPassword?: string;
}

/**
 * 
 */
export interface CreateHistogramRequest {
    /**
     * 
     */
    histoname?: string;
    /**
     * 
     */
    reset?: number;
    /**
     * 
     */
    type?: any;
    /**
     * 
     */
    signals?: Array<HistogramSignal>;
}

/**
 * 
 */
export interface FeatureRequest {
    /**
     * 
     */
    source: string;
    /**
     * 
     */
    lastUpdateAt?: string;
    /**
     * 
     */
    features: Array<Feature>;
}

/**
 * 
 */
export interface Feature {
    /**
     * 
     */
    name: string;
    /**
     * 
     */
    expiryDate: string;
}

/**
 * 
 */
export interface RefreshActiveFeaturesOnVinRequest {
    /**
     * 
     */
    vins?: Array<string>;
}

/**
 * 
 */
export interface MergePolicyRequest {
    /**
     * 
     */
    features?: Array<string>;
}

/**
 * 
 */
export interface SearchRequest {
    /**
     * 
     */
    pageSize?: number;
    /**
     * 
     */
    page?: Record<string, unknown>;
    /**
     * 
     */
    filterExpression?: FilterExpression;
}

/**
 * 
 */
export interface FilterExpression {
    /**
     * 
     */
    field?: Record<string, unknown>;
}

/**
 *
 */
export interface UpdateVinProfileRequest {
    /**
     *
     */
    vin: string;
    /**
     *
     */
    policyId?: number;
    /**
     *
     */
    expirationDateExtend?: number;
    /**
     *
     */
    notifications?: boolean;
    /**
     *
     */
    debug?: boolean;
    /**
     *
     */
    debugDays?: number;
    /**
     *
     */
    lastKnown?: boolean;
    /**
     *
     */
    lastKnownDays?: number;
    /**
     *
     */
    policies?: Array<number>;
    /**
     *
     */
    renew?: number;
}

/**
 * 
 */
export interface UpdateVinProfileResponse {
    /**
     * 
     */
    Attributes: VinProfile;
}

/**
 * 
 */
export interface ApiPaginatedSearchResponse {
    /**
     * 
     */
    data?: Array<Record<string, unknown>>;
    /**
     * 
     */
    pageInfo?: PageInfo;
}

/**
 * 
 */
export interface PageInfo {
    /**
     * 
     */
    totalItems?: number;
    /**
     * 
     */
    lastPageKey?: Record<string, unknown>;
}

/**
 * 
 */
export interface BatchPolicyOperationResultResponse {
    /**
     * 
     */
    updatedItems?: Array<SinglePolicyOperationResultResponse>;
    /**
     * 
     */
    failedItems?: Array<SinglePolicyOperationResultResponse>;
}

/**
 * 
 */
export interface SinglePolicyOperationResultResponse {
    /**
     * 
     */
    id?: number;
    /**
     * 
     */
    status?: any;
    /**
     * 
     */
    message?: string;
}

/**
 * 
 */
export interface BatchMergePolicyResultResponse {
    /**
     * 
     */
    updatedItems?: Array<SingleMergePolicyResultResponse>;
    /**
     * 
     */
    failedItems?: Array<SingleMergePolicyResultResponse>;
}

/**
 * 
 */
export interface SingleMergePolicyResultResponse {
    /**
     * 
     */
    fname: string;
    /**
     * 
     */
    policyId?: number;
    /**
     * 
     */
    message?: string;
}

/**
 * 
 */
export interface BatchReMergePolicyResultResponse {
    /**
     * 
     */
    updatedItems?: Array<string>;
    /**
     * 
     */
    failedItems?: Array<string>;
}

/**
 * 
 */
export interface GetSyntheticsResponse {
    /**
     * 
     */
    Items: Array<Synthetic>;
}

/**
 * 
 */
export interface CreateSyntheticRequest {
    /**
     * 
     */
    param?: string;
    /**
     * 
     */
    source?: any;
    /**
     * 
     */
    operator?: Operator;
    /**
     * 
     */
    operands?: Array<AdaElement>;
}

/**
 * 
 */
export interface GetGroupsResponse {
    /**
     * 
     */
    data: Array<Group>;
}

/**
 * 
 */
export interface AdaJsonPolicy {
    /**
     * 
     */
    grp_id: number;
    /**
     * 
     */
    pname: string;
    /**
     * 
     */
    renew: number;
    /**
     * 
     */
    backup_window: number;
    /**
     * 
     */
    delay: number;
    /**
     * 
     */
    plans: Array<AdaJsonPollEventPlan | AdaJsonPopupPlan>;
    /**
     * 
     */
    type: any;
}

/**
 * 
 */
export interface AdaJsonPollEventPlan {
    /**
     * 
     */
    type: JsonPlanType;
    /**
     * 
     */
    period: number;
    /**
     * 
     */
    transfer_window: number;
    /**
     * 
     */
    signals?: Array<Signal>;
    /**
     * 
     */
    histograms?: Array<Histogram>;
    /**
     * 
     */
    event?: Array<Event>;
}

/**
 * 
 */
export interface AdaJsonPopupPlan {
    /**
     * 
     */
    type: JsonPlanType;
    /**
     * 
     */
    title: string;
    /**
     * 
     */
    rbtntext: string;
    /**
     * 
     */
    lbtntext: string;
}

/**
 * 
 */
export interface Signal {
    /**
     * 
     */
    name: string;
    /**
     * 
     */
    type: PropertyType;
    /**
     * 
     */
    subtype?: string;
}

/**
 * 
 */
export interface Histogram {
    /**
     * 
     */
    histoid: number;
    /**
     * 
     */
    histoname: string;
    /**
     * 
     */
    multiDimensional?: boolean;
    /**
     * 
     */
    signals?: Array<HistogramSignal>;
    /**
     * 
     */
    reset?: number;
    /**
     * 
     */
    created_timestamp?: number;
    /**
     * 
     */
    rangeCount?: number;
    /**
     * 
     */
    type?: HistogramType;
}

/**
 * 
 */
export interface HistogramSignal {
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    type?: HistogramSignalType;
    /**
     * 
     */
    ranges?: Array<HistogramBin>;
    /**
     * 
     */
    subsignals?: Array<HistogramSignal>;
}

/**
 * 
 */
export interface HistogramBin {
    /**
     * 
     */
    min?: number;
    /**
     * 
     */
    max?: number;
}

/**
 * 
 */
export interface Event {
    /**
     * 
     */
    comparators?: Array<EventComparators>;
    /**
     * 
     */
    compareToSignals?: Array<string>;
    /**
     * 
     */
    compareType?: Array<string>;
    /**
     * 
     */
    signalName?: string;
    /**
     * 
     */
    signalType?: string;
    /**
     * 
     */
    threshold?: Array<string>;
}

/**
 * 
 */
export interface AdaElement {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
}

/**
 *
 */
export interface CustHisto {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * Check with server, conflicts between definitions in histograms api and policy api
     * Should Operands be AdaElementBySource or Synth
     */
    operands: Array<CustomHistogramOperand | Synth>;
}

/**
 * 
 */
export interface CustHistoDef {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * 
     */
    operands: Array<CustomHistogramOperand | AdaElementBySource>;
    /**
     * Check with server should ranges be string array or string
     * Conflict with histogram api (string) and server api
     */
    ranges?: string;
}

/**
 * 
 */
export interface CustomHistogramOperand {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * 
     */
    ranges: string;
}

/**
 * 
 */
export interface Synth {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * 
     */
    operator: Operator;
    /**
     * 
     */
    operands: Array<AdaElementBySource>;
}

/**
 * 
 */
export interface Synthetic {
    /**
     * 
     */
    synthid: number;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    source?: any;
    /**
     * 
     */
    operator?: Operator;
    /**
     * 
     */
    operands?: Array<AdaElementBySource>;
    /**
     * 
     */
    createdTimeStamp?: number;
}

/**
 * 
 */
export interface CreateStreamConfigRequest {
    /**
     * 
     */
    policy: string;
    /**
     * 
     */
    streamName: string;
}

/**
 * 
 */
export interface CreateConsumerRoleRequest {
    /**
     * 
     */
    streamName: string;
    /**
     * 
     */
    thirdPartyArn: string;
    /**
     * 
     */
    consumer: string;
    /**
     * 
     */
    region: ConsumerRoleRegion;
}

/**
 * 
 */
export interface CreateConsumerRoleResponse {
    /**
     * 
     */
    role?: string;
    /**
     * 
     */
    streamName?: string;
    /**
     * 
     */
    region?: ConsumerRoleRegion;
    /**
     * 
     */
    externalId: string;
}

/**
 * 
 */
export interface CreateStreamRequest {
    /**
     * 
     */
    streamName: string;
}

/**
 * 
 */
export interface GetStreamConfigResponse {
    /**
     * 
     */
    configData?: Record<string, unknown>;
}

/**
 * 
 */
export interface CreateStreamConfigResponse {
    /**
     * 
     */
    status?: any;
    /**
     * 
     */
    message?: string;
}

/**
 * 
 */
export interface CreateStreamResponse {
    /**
     * 
     */
    status?: any;
    /**
     * 
     */
    message?: string;
}

/**
 * 
 */
export interface GetStreamsResponse {
    /**
     * 
     */
    streams?: Array<string>;
}

/**
 * 
 */
export interface GetUserCreatedPoliciesResponse {
    /**
     * 
     */
    userCreatedPolicyDetails: Array<UserCreatedPolicyDetails>;
}

/**
 * 
 */
export interface UserCreatedPolicyDetails {
    /**
     * 
     */
    PolicyId?: string;
    /**
     * 
     */
    PolicyName?: string;
    /**
     * 
     */
    Time_stamp?: string;
}

/**
 * 
 */
export interface GetUserPoliciesResponse {
    /**
     * 
     */
    userPolicyDetails: Array<UserPolicyDetails>;
}

/**
 * 
 */
export interface UserPolicyDetails {
    /**
     * 
     */
    PolicyId: number;
    /**
     * 
     */
    PreviousId?: number;
    /**
     * 
     */
    PolicyName: string;
    /**
     * 
     */
    FeatureName?: string;
    /**
     * 
     */
    Timestamp: string;
    /**
     * 
     */
    Operation?: string;
}

/**
 * 
 */
export interface UpdateUserDetailsRequest {
    /**
     * 
     */
    theme?: string;
    /**
     * 
     */
    region?: string;
}

/**
 * 
 */
export interface UpdateUserDetailsResponse {
    /**
     * 
     */
    status?: any;
}

/**
 * 
 */
export interface GetUserDetailsResponse {
    /**
     * 
     */
    TID?: string;
    /**
     * 
     */
    FirstName?: string;
    /**
     * 
     */
    LastName?: string;
    /**
     * 
     */
    Theme?: string;
    /**
     * 
     */
    Region?: string;
}

/**
 * 
 */
export interface CreateVCRIotRule {
    /**
     * 
     */
    device?: string;
    /**
     * 
     */
    startEpoch?: string;
    /**
     * 
     */
    endEpoch?: string;
}

/**
 * 
 */
export interface VCRIotRule {
    /**
     * 
     */
    ruleName?: string;
    /**
     * 
     */
    createdAt?: string;
    /**
     * 
     */
    ruleDisabled?: boolean;
}

/**
 * 
 */
export interface VCRIotResponse {
    /**
     * 
     */
    recordingId?: string;
    /**
     * 
     */
    iotRule?: VCRIotRule;
}

/**
 * 
 */
export interface DeleteVCRIotRuleResponse {
    /**
     * 
     */
    ruleDeleted?: boolean;
}

/**
 *
 */
export interface VCRRecording {
    /**
     *
     */
    vin?: string;
    /**
     *
     */
    device?: string;
    /**
     *
     */
    recordingId?: string;
    /**
     *
     */
    start_time?: number;
    /**
     *
     */
    end_time?: number;
    /**
     *
     */
    cancelled?: boolean;
    /**
     *
     */
    activeFeatures?: string;
    /**
     *
     */
    featureRequests?: Record<string, unknown>;
    /**
     *
     */
    id?: number;
    /**
     *
     */
    taskDirectory?: Record<string, unknown>;
  }

/**
 * 
 */
export interface FeatureApproval {
    /**
     * 
     */
    status?: string;
    /**
     * 
     */
    request_user?: string;
    /**
     * 
     */
    request_timestamp?: string;
    /**
     * 
     */
    approval_user?: string;
    /**
     * 
     */
    approval_timestamp?: string;
    /**
     * 
     */
    feature: string;
}

/**
 * 
 */
export interface GetFeatureApprovalRequestResponse {
    /**
     * 
     */
    featureApprovalRequests?: Array<FeatureApproval>;
    /**
     * 
     */
    count?: number;
    /**
     * 
     */
    lastEvaluatedKey?: Record<string, unknown>;
}

/**
 * 
 */
export interface ReviewFeatureApprovalRequestResponse {
    /**
     * 
     */
    feature?: string;
    /**
     * 
     */
    status?: string;
}

/**
 * 
 */
export interface CreateFeatureApprovalRequestResponse {
    /**
     * 
     */
    feature?: string;
    /**
     * 
     */
    test_feature?: string;
}

/**
 * 
 */
export enum SensorType {
    "KONA" = "kona",
    "LOCATION" = "location",
    "CLOCK" = "clock",
    "NAPI" = "napi",
    "ANDROID_ACTIVITY_MANAGER" = "androidActivityManager",
    "ANDROID_LOCATION" = "androidLocation",
    "ANDROID_TELEPHONY_MANAGER" = "androidTelephonyManager",
    "USER_MANAGER" = "userManager",
}

/**
 * 
 */
export enum UserPermissions {
    "ADA_API_POLICY_READ_NA" = "AdaApiPolicyRead_na",
    "ADA_API_POLICY_WRITE_NA" = "AdaApiPolicyWrite_na",
    "ADA_API_VIN_PROFILE_READ_NA" = "AdaApiVinProfileRead_na",
    "ADA_API_USER_READ_NA" = "AdaApiUserRead_na",
    "ADA_API_USER_WRITE_NA" = "AdaApiUserWrite_na",
    "ADA_API_POLICY_ASSIGNMENT_NA" = "AdaApiPolicyAssignment_na",
    "ADA_API_POLICY_FEATURE_WRITE_NA" = "AdaApiPolicyFeatureWrite_na",
    "ADA_API_FEATURE_WRITE_NA" = "AdaApiFeatureWrite_na",
    "ADA_API_STREAM_READ_WRITE_NA" = "AdaApiStreamReadWrite_na",
    "ADA_API_WEB_ACCESS_NA" = "AdaApiWebAccess_na",
    "ADA_API_POLICY_READ_EU" = "AdaApiPolicyRead_eu",
    "ADA_API_POLICY_WRITE_EU" = "AdaApiPolicyWrite_eu",
    "ADA_API_VIN_PROFILE_READ_EU" = "AdaApiVinProfileRead_eu",
    "ADA_API_USER_READ_EU" = "AdaApiUserRead_eu",
    "ADA_API_USER_WRITE_EU" = "AdaApiUserWrite_eu",
    "ADA_API_POLICY_ASSIGNMENT_EU" = "AdaApiPolicyAssignment_eu",
    "ADA_API_POLICY_FEATURE_WRITE_EU" = "AdaApiPolicyFeatureWrite_eu",
    "ADA_API_FEATURE_WRITE_EU" = "AdaApiFeatureWrite_eu",
    "ADA_API_STREAM_READ_WRITE_EU" = "AdaApiStreamReadWrite_eu",
    "ADA_API_WEB_ACCESS_EU" = "AdaApiWebAccess_eu",
}

/**
 * 
 */
export enum ConsumerRoleRegion {
    "US_EAST_2" = "us-east-2",
    "US_WEST_2" = "us-west-2",
    "EU_WEST_1" = "eu-west-1",
}

/**
 * 
 */
export interface GetVinScheduleResponse {
    /**
     * 
     */
    vin: string;
    /**
     * 
     */
    device?: string;
    /**
     * 
     */
    startup?: Record<string, unknown>;
}

/**
 * 
 */
export interface OnDemandRequest {
}

/**
 * 
 */
export interface GetDeviceResponse {
    /**
     * 
     */
    data: Array<VinProfile>;
}

/**
 * 
 */
export interface CreateProtobufHistogramRequest {
    /**
     * 
     */
    histoname?: string;
    /**
     * 
     */
    proto: boolean;
    /**
     * 
     */
    source?: string;
    /**
     * 
     */
    param?: string;
    /**
     * 
     */
    operands?: Array<Synth | CustHistoDef>;
}

/**
 * 
 */
export interface AssignPolicyRequest {
    /**
     * 
     */
    assignPolicyRequests?: Array<PolicyVinRequest>;
}

/**
 * 
 */
export interface UpdateVinFeaturesRequest {
    /**
     * 
     */
    updateFeatureRequest?: Array<VinFeatureRequest>;
}

/**
 * 
 */
export interface VinFeatureRequest {
    /**
     * 
     */
    vin?: string;
    /**
     * 
     */
    device?: string;
    /**
     * 
     */
    featureRequests?: Array<FeatureRequest>;
}

/**
 * 
 */
export interface PolicyVinRequest {
    /**
     * 
     */
    policy?: number;
    /**
     * 
     */
    vins?: Array<Record<string, unknown>>;
}

/**
 * 
 */
export interface BatchVinOperationResultResponse {
    /**
     * 
     */
    updatedItems?: Array<SingleVinOperationResultResponse>;
    /**
     * 
     */
    failedItems?: Array<SingleVinOperationResultResponse>;
}

/**
 * 
 */
export interface SingleVinOperationResultResponse {
    /**
     * 
     */
    vin: string;
    /**
     * 
     */
    device?: string;
    /**
     * 
     */
    status: any;
    /**
     * 
     */
    message?: string;
}

/**
 *
 */
export interface VinProfile {
    /**
     *
     */
    vin: string;
    /**
     *
     */
    device: string;
    /**
     *
     */
    estimatedNextCheckIn?: string;
    /**
     *
     */
    id?: number;
    /**
     *
     */
    last_check_in?: string;
    /**
     *
     */
    exp_date_extend?: number;
    /**
     *
     */
    exp_date?: string;
    /**
     *
     */
    last_check_in_source?: string;
    /**
     *
     */
    last_check_in_region?: string;
    /**
     *
     */
    vendor?: string;
    /**
     *
     */
    activeFeatures?: string;
    /**
     *
     */
    notifications?: boolean;
    /**
     *
     */
    scheduling?: boolean;
    /**
     *
     */
    featureRequests?: Array<FeatureRequest>;
    /**
     *
     */
    last_check_in_env?: string;
    /**
     *
     */
    debug?: boolean;
    /**
     * 
     */
    debugExpiry?: string;
    /**
     *
     */
    lastKnown?: boolean;
    /**
     * 
     */
    lastKnownExpiry?: string;
    /**
     *
     */
    appVersion?: string;
    /**
     *
     */
    fwVersion?: string;
    /**
     *
     */
    policies?: Record<string, unknown>;
    /**
     *
     */
    renew?: number;
}

/**
 * 
 */
export interface AdaProtobufPolicy {
    /**
     * 
     */
    grp_id: number;
    /**
     * 
     */
    pname: string;
    /**
     * 
     */
    renew?: number;
    /**
     * 
     */
    plans: Array<AdaProtobufPlan>;
    /**
     * 
     */
    type: any;
    /**
     * 
     */
    createdByUser?: string;
}

/**
 * 
 */
export interface AdaProtobufPlan {
    /**
     * 
     */
    type: ProtobufPlanType;
    /**
     * 
     */
    period: number;
    /**
     * 
     */
    bundle_window?: number;
    /**
     * 
     */
    transfer_window?: number;
    /**
     * 
     */
    data?: Array<AdaElementBySource>;
    /**
     * 
     */
    filter?: AdaElementBySource;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * 
     */
    compress_threshold?: number;
    /**
     * 
     */
    backup_window?: number;
    /**
     * 
     */
    delay?: number;
}

/**
 * 
 */
export interface TimeseriesDef {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * 
     */
    operands: Array<AdaElementBySource>;
}

/**
 * 
 */
export interface AdaTimeseries {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * 
     */
    operands: Array<AdaElementBySource>;
}

/**
 * 
 */
export interface Popup {
    /**
     * 
     */
    source: Source;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    param: string;
    /**
     * 
     */
    flags?: Array<Flag>;
    /**
     * 
     */
    popup_config: string;
}

/**
 * 
 */
export interface File {
    /**
     * 
     */
    operator?: any;
    /**
     * 
     */
    operands?: Array<FileOperand>;
    /**
     * 
     */
    source: Source;
}

/**
 * 
 */
export interface FileOperand {
    /**
     * 
     */
    param?: string;
    /**
     * 
     */
    source: Source;
}

/**
 * 
 */
export interface StdPopup {
    /**
     * 
     */
    operands?: Array<StdPopupOperand>;
    /**
     * 
     */
    param: string;
     /**
     * 
     */
     source?: any;
  }
  
  /**
  * The on-demand policies associated with each button
  */
  export interface StdPopupOperand {
    /**
     * 
     */
    param?: string;
    /**
     * 
     */
    source?: any;
  }
  
  /**
  * essential popup information
  */
  export interface BasicStdPopupParam {
    /**
     * 
     */
    title?: string;
    /**
     * 
     */
    message?: string;
    /**
     * 
     */
    button?: string;
    /**
     * 
     */
    timeout?: number;
  }
  
  /**
  * will be ignored on VP4R devices
  */
  export interface R1StdPopupParam {
    /**
     * 
     */
    "header-text"?: string;
    /**
     * 
     */
    "header-icon"?: any;
    /**
     * 
     */
    "header-text-color"?: string;
    /**
     * 
     */
    "header-bg-color"?: string;
    /**
     * 
     */
    type?: any;
  }

/**
 * 
 */
export interface ReplacePolicyRequest {
    /**
     * 
     */
    oldid?: number;
    /**
     * 
     */
    newid?: number;
}

/**
 * 
 */
export interface ReplacePolicyResponse {
    /**
     * 
     */
    status?: any;
    /**
     * 
     */
    message?: string;
}

/**
 * 
 */
export interface UnschedulePolicyRequest {
    /**
     * 
     */
    oldid?: number;
    /**
     * 
     */
    newid?: number;
}

/**
 * 
 */
export interface UnschedulePolicyResponse {
    /**
     * 
     */
    status?: any;
    /**
     * 
     */
    message?: string;
}

/**
 * 
 */
export interface SignUpdatePolicyResponse {
    /**
     * 
     */
    status?: any;
    /**
     * 
     */
    message?: string;
}

/**
 * 
 */
export interface DisableDataRequest {
    /**
     * 
     */
    vins?: Array<string>;
}

/**
 * 
 */
export type AdaElementBySource = AdaElement | CustHisto | TimeseriesDef | Synth | Popup | File | StdPopup;


/**
 * 
 */
export enum JsonPlanType {
    "POLL" = "poll",
    "EVENT" = "event",
    "POPUP" = "popup",
}

/**
 * 
 */
export enum PropertyType {
    "KONA" = "kona",
    "LOCATION" = "location",
    "CLOCK" = "clock",
    "DIAG" = "diag",
    "COMMAND" = "command",
    "HISTOGRAM" = "histogram",
}

/**
 * 
 */
export enum HistogramType {
    "CUSTOM" = "custom",
    "TELEMATIC" = "telematic",
}

/**
 * 
 */
export enum HistogramSignalType {
    "SENSOR" = "sensor",
}

/**
 * 
 */
export enum EventComparators {
    ">" = ">",
    "<" = "<",
    "=" = "=",
    "<=" = "<=",
    ">=" = ">=",
}

/**
 * 
 */
export enum ProtobufPlanType {
    "POLL" = "poll",
    "ONCHANGE" = "onchange",
    "TIMESERIES" = "timeseries",
    "TIMESERIES_ONCHANGE" = "timeseries-onchange",
}

/**
 * 
 */
export enum Source {
    "NAPI" = "napi",
    "CONST" = "const",
    "DIAG" = "diag",
    "ADV_DIAG" = "adv-diag",
    /**
     * TODO: update openapi spec to include
     * diag_translate source
     */
    "DIAG_TRANSLATE" = "diag-translation",
    "SYNTH" = "synth",
    "COMMAND" = "command",
    "LOCATION" = "location",
    "KONA" = "kona",
    "CUST_HISTO_DEF"="cust-histo-def",
    "PERSIST" = "persist",
    "REF" = "ref",
    "PERSIST_REF" = "persist-ref",
    "OS_HISTO" = "os-histo",
    "TIMESERIES_DEF"="timeseries-def", // TODO confirm with server
    "ADA_TIMESERIES"="ada-timeseries", // TODO confirm with server
    "POPUP" = "popup",
    "WAIT" = "wait",
    "INTERNAL" = "internal",
    "UCONNECT_MANAGER" = "UconnectManager",
    "FILE" = "file",
    "STD_POPUP"="std-popup",
}

/**
 * 
 */
export enum Flag {
    "ONCHANGE" = "onchange",
    "PERSIST" = "persist",
    "RESET" = "reset",
    "COMPRESS" = "compress",
    "ENCRYPT" = "encrypt",
    "PERSIST-REF" = "persist-ref",
    "JSON-REF" = "json-ref",
    "IGNORE-E#x" = " ignore_E#x"
}

/**
 * 
 */
export enum Operator {
    "ADD"="ADD",
    "SUB"="SUB",
    "MUL"="MUL",
    "NEG"="NEG",
    "DIV"="DIV",
    "MOD"="MOD",
    "LT"="LT",
    "LTE"="LTE",
    "EQ"="EQ",
    "GTE"="GTE",
    "GT"="GT",
    "NEQ"="NEQ",
    "AND"="AND",
    "OR"="OR",
    "NOT"="NOT",
    "LSHIFT"="LSHIFT",
    "RSHIFT"="RSHIFT",
    "ROUND"="ROUND",
	"CEIL"="CEIL",
	"FLOOR"="FLOOR",
	"MAX"="MAX",
	"MIN"="MIN",
	"ABS"="ABS",
	"CBRT"="CBRT",
	"EXP"="EXP",
	"LOG"="LOG",
	"LOG10"="LOG10",
	"POW"="POW",
	"RANDOM"="RANDOM",
	"SQRT"="SQRT",
    "BITAND"="BITAND",
    "BITOR"="BITOR",
    "BITNOT"="BITNOT",
    "BITXOR"="BITXOR",
    "BYTE_AT"="BYTE_AT",
    "XOR"="XOR",
    "STREQ"="STREQ",
	"STREQIC"="STREQIC",
	"STRNEQ"="STRNEQ",
	"STRNEQIC"="STRNEQIC",
	"STRCOMPARE"="STRCOMPARE",
	"STRCOMPAREIC"="STRCOMPAREIC",
	"STRSUBSTRING"="STRSUBSTRING",
	"STRCONCAT"="STRCONCAT",
	"STRLENGTH"="STRLENGTH",
	"STRSTARTSWITH"="STRSTARTSWITH",
	"STRSTARTSWITHIC"="STRSTARTSWITHIC",
	"STRENDSWITH"="STRENDSWITH",
	"STRENDSWITHIC"="STRENDSWITHIC",	
	"STRCONTAINS"="STRCONTAINS",
	"STRCONTAINSIC"="STRCONTAINSIC",
	"STRGT"="STRGT",
	"STRGTIC"="STRGTIC",
	"STRLT"="STRLT",
	"STRLTIC"="STRLTIC",
	"STRGTEQ"="STRGTEQ",
	"STRGTEQIC"="STRGTEQIC",
	"STRLTEQ"="STRLTEQ",
	"STRLTEQIC"="STRLTEQIC"
}

/**
 * 
 */
export enum Device {
    "DEFAULT"="default",
    "R_1"="r1",
    "R_2"="r2",
    "TBM"="tbm",
    "VP_4_R"="vp4r"
}

/**
 * The intended region for the feature
 */
export enum FeatureApprovalRegion {
    "NA"="na",
    "EE"="ee",
    "IAP"="iap",
    "LATAM"="latam",
    "MEA"="mea",
    "CHINA"="china",
    "CROSS"="cross",
}

/**
 * The intended device for the feature
 */
export enum FeatureApprovalDevice {
    "BSRF"="bsrf",
    "HPC"="hpc",
    "R_1"="r1",
    "R_2"="r2",
    "RTCU"="rtcu",
    "VP_4_R"="vp4r",
    "TBM"="tbm",
    "MULTI"="multi",

}

/**
 * The status of the feature approval request. Test features are automatically accepted
 */
export enum FeatureApprovalStatus {
    "PENDING"="PENDING",
    "ACCEPTED"="ACCEPTED",
    "REJECTED"="REJECTED",
}

/**
 * The review action for the specified feature
 */
export enum FeatureApprovalAction {
    "ACCEPT"="ACCEPT",
    "REJECT"="REJECT",
}
