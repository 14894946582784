import axios, {
    AxiosError,
    AxiosResponse,
    RawAxiosRequestHeaders
} from "axios";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { AXIOS_CANCEL_CODE } from "./constants";
import { safeInvoke } from "./utils/safeInvoke";

/**
 * The error handlers for the RequestManager.
 */
export interface ErrorHandlers {
	/**
	 * Handles 401
	 * @param errorMessage - the error message.
	 */
	handleUnauthorizedError(errorMessage: string): void;
	/**
	 * Handles 403
	 * @param errorMessage - the error message.
	 */
	handleForbiddenError(errorMessage: string): void;
	/**
	 * Handles 500
	 * @param errorName - the error name.
	 * @param errorMessage - the error message.
	 */
	handleInternalServerError(errorName: string, errorMessage: string): void;
	/**
	 * Handles 400
	 * @param errorMessage - the error message.
	 */
	handleBadRequestError(errorMessage: string): void;
	/**
	 * Handles any other error.
	 * @param errorMessage - the error message.
	 */
	handleError(errorMessage: string): void;
	/**
	 * Handles 404
	 * @param errorMessage - the error message.
	 */
	handleNotFoundError(errorMessage: string): void;
}

/**
 * The definition of a RequestManager API request.
 */
export type ApiRequest<T, U, R, Q> = (
	params: T,
	payload: U,
	headers: RawAxiosRequestHeaders,
	signal: AbortSignal,
	queryParameters?: Q,
	baseUrl?: string
) => Promise<AxiosResponse<R>>;

/**
 * The object type for arguments that can be passed to the api request {@link ApiRequest}.
 * The optionality differs from the actual api request for developer convenience while
 * using the `send` function so that unused options may be omitted.
 */
export interface ApiRequestArguments<T, U, Q> {
	/**
	 * The api endpoint parameter.
	 * Used for things like IDs.
	 */
	params?: T;
	/**
	 * The HTTP request payload.
	 */
	payload?: U;
	/**
	 * The url query parameters
	 */
	queryParameters?: Q;
}

/**
 * Class which manages all api requests and allows error bubbling
 * and request cancellation.
 */
export class RequestManager {
	private baseUrl: string | undefined;
	private requestHeaders: RawAxiosRequestHeaders;
	private errorHandlers: ErrorHandlers;
	private pendingRequests: Record<string, AbortController>;

	/**
	 * @inheritdoc
	 *
	 * HACK: This `baseUrl` parameter should be removed in favor of a more robust solution with loading
	 * base urls?
	 */
	public constructor(errorHandlers: ErrorHandlers, accessToken: string | null, baseUrl?: string) {
		let requestHeaders: RawAxiosRequestHeaders = {
			"Content-Type": "application/json",
			Accept: "application/json",
			"x-api-key": process.env.REACT_APP_API_KEY
		};
		if (accessToken) {
			requestHeaders = {
				...requestHeaders,
				Authorization: `Bearer ${accessToken}`
			};
		}

		this.requestHeaders = requestHeaders;
		this.errorHandlers = { ...errorHandlers };
		this.pendingRequests = {};
		this.baseUrl = baseUrl;
	}

	/**
	 * Get the current base url.
	 * @returns the current base url.
	 */
	public getBaseUrl(): string {
		return this.baseUrl || "";
	}

	/**
	 * Sets the base url to the provided string.
	 * @param baseUrl - the new base url
	 */
	public setBaseUrl(baseUrl: string | undefined): void {
		this.baseUrl = baseUrl;
	}

	/**
	 * Sets the access token.
	 * @param accessToken
	 */
	public setAccessToken(accessToken: string): void {
		this.requestHeaders = {
			...this.requestHeaders,
			Authorization: `Bearer ${accessToken}`
		};
	}

	/**
	 * Handles caught errors thrown by axios calls within {@link RequestManager}.
	 * @param error - the axios request error.
	 */
	private handleError(error: AxiosError): void {
		if (error.response) {
			const HTTP_STATUS = error.response.status;
			if (HTTP_STATUS === 401) {
				safeInvoke(this.errorHandlers.handleUnauthorizedError, error.message);
			} else if (HTTP_STATUS === 403) {
				safeInvoke(this.errorHandlers.handleForbiddenError, error.config?.url || "");
			} else if (HTTP_STATUS === 404) {
				safeInvoke(this.errorHandlers.handleNotFoundError, error.message);
			} else if (HTTP_STATUS === 500) {
				safeInvoke(this.errorHandlers.handleInternalServerError, error.name, error.message);
			} else if (HTTP_STATUS === 400) {
				const responseBody: any = error.response.data;
				const errorMessage = responseBody.message?.message || responseBody.message;
				safeInvoke(this.errorHandlers.handleBadRequestError, errorMessage);
			}
		} else {
			safeInvoke(this.errorHandlers.handleError, error.message);
		}
	}

	/**
	 * Sends a request to the API.
	 * @param apiRequest - the api request function.
	 * @param apiRequestArguments - arguments to supply to the apiRequest parameter.
	 * @return a promise that resolves to the api result.
	 */
	public send<T, U, R, Q>(
		apiRequest: ApiRequest<T, U, R, Q>,
		apiRequestArguments?: ApiRequestArguments<T, U, Q>
	): Promise<AxiosResponse<R>> {
		// Retrieve API request configurations.
		const requestId = uuidv4();
		const abortController = new AbortController();
        this.pendingRequests[requestId] = abortController;
		// Call the API endpoint with extracted request configurations.
		return new Promise((resolve, reject) => {
			apiRequest(
				apiRequestArguments?.params as T,
				apiRequestArguments?.payload as U,
				this.requestHeaders,
				abortController.signal,
				apiRequestArguments?.queryParameters as Q,
				this.baseUrl
			)
				// Pass response to the caller.
				// NOTE: These API responses are first passed thru to the interceptor 
				// prior to this ".then()" block. See <App /> for more details.
				.then((response) => {
					if (
						// Request was cancelled
						axios.isCancel(response) ||
						(response &&
							typeof response === "object" &&
							"code" in response &&
							(response as any).code === AXIOS_CANCEL_CODE)
					) {
						// Will return cancel code to calling function to handle in catch block
						reject((response as any).code);
					}
					// Return response to calling component
					resolve(response);
				})
				.catch((error) => {
					// Axios masks the error so .toJSON() exposes more data, like config and code if available
					// Pass rejection reason to the caller
					// of this send function.
					this.handleError(error);
					reject(error);
				})
				.finally(() => {
					delete this.pendingRequests[requestId];
				});
		});
	}

	/**
	 * Cancels all pending requests stored in the map.
	 */
	public cancelAllPendingRequests(): void {
		_.forEach(this.pendingRequests, (abortController) => {
            abortController.abort();
        });
		this.pendingRequests = {};
	}
}

/**
 * Consumes the request manager-thrown error and does something
 * to internally represent it.
 * // NOTE: this should only be relevant to the developer.
 * @param error - the javascript/axios error.
 */
export function swallowRequestManagerError(error: AxiosError): void {
	console.log(error);
}
