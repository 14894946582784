import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import * as ApiModelTypes from "../../api/apiModelTypes";
import { Home } from "../../scenes/Home";
import { Props as LandingPageProps } from "../../scenes/LandingPage";
import {
	DefaultInfoPanelConfig,
	InfoPanelConfig
} from "../../utils/infoPanelUtils/infoPanelConfig";
import { InfoPanelContext } from "../../utils/infoPanelUtils/infoPanelContext";
import { ErrorPage } from "../ErrorPage";
import InfoPanel from "../InfoPanel";
import { LoadingLogo } from "../LoadingLogo";
import { UserPermissionGuard } from "../UserPermissionGuard";
import * as Styled from "./routedBodyStyles";
import { SceneProps } from "./scenesUtil";

/**
 * Lazy load each scene. Used with named imports only.
 */
const RoutedVehicleView = lazy(() =>
	import("./RoutedVehicleView").then((module) => ({ default: module.RoutedVehicleView }))
);
const RoutedPolicyView = lazy(() =>
	import("./RoutedPolicyView").then((module) => ({ default: module.RoutedPolicyView }))
);
const RoutedFeatureNames = lazy(() =>
	import("./RoutedFeatureNames").then((module) => ({ default: module.RoutedFeatureNames }))
);
const RoutedTraceabilityMatrixView = lazy(() =>
	import("./RoutedTraceabilityMatrixView").then((module) => ({
		default: module.RoutedTraceabilityMatrixView
	}))
);
const RoutedHistogramView = lazy(() =>
	import("./RoutedHistogramView").then((module) => ({ default: module.RoutedHistogramView }))
);
const RoutedSensorsView = lazy(() =>
	import("./RoutedSensorsView").then((module) => ({ default: module.RoutedSensorsView }))
);
const RoutedSyntheticsView = lazy(() =>
	import("./RoutedSyntheticView").then((module) => ({ default: module.RoutedSyntheticsView }))
);
const RoutedStreamsView = lazy(() =>
	import("./RoutedStreamView").then((module) => ({ default: module.RoutedStreamsView }))
);
const RoutedVCRDashboard = lazy(() =>
	import("./RoutedVCRDashboard").then((module) => ({ default: module.RoutedVCRDashboard }))
);
const RoutedUserView = lazy(() =>
	import("./RoutedUserView").then((module) => ({ default: module.RoutedUserView }))
);
const RoutedUserProfileView = lazy(() =>
	import("./RoutedUserProfileView").then((module) => ({ default: module.RoutedUserProfileView }))
);
const RoutedGroupView = lazy(() =>
	import("./RoutedGroupView").then((module) => ({ default: module.RoutedGroupView }))
);
const RoutedVehicleTestingView = lazy(() =>
	import("./RoutedVehicleTestingView").then((module) => ({
		default: module.RoutedVehicleTestingView
	}))
);
const RoutedSupportView = lazy(() =>
	import("./RoutedSupportView").then((module) => ({ default: module.RoutedSupportView }))
);

/**
 * Name of this component.
 */
const COMPONENT_NAME = "RoutedBody";

/**
 * Props interface for this component.
 */
export interface Props extends SceneProps, LandingPageProps {
	[x: string]: any;
	/**
	 * The user.
	 * TODO: define type after api code is generated.
	 */
	user: ApiModelTypes.Me;
	/**
	 * Called when the intro messages is to be hidden.
	 */
	onHideIntroMessage(): void;
	/**
	 * Flag to toggle theme mode icon
	 */
	isDarkMode: boolean;
}

/**
 * The routed body.
 */
export const RoutedBody: React.FC<Props> = (props) => {
	// The current open state for the info panel
	const [isInfoPanelOpen, setIsInfoPanelOpen] = React.useState(false);

	// The current info panel's config
	const [infoPanelConfig, setInfoPanelConfig] =
		React.useState<InfoPanelConfig>(DefaultInfoPanelConfig);

	// The title of the section to programmatically open
	const [openPanelSection, setOpenPanelSection] = React.useState<string | undefined>(undefined);

	/**
	 * Sets the open state for the info panel
	 * @param newOpenState the new state to set
	 */
	const setInfoPanelOpen = React.useCallback((newOpenState: boolean) => {
		setIsInfoPanelOpen(newOpenState);
	}, []);

	/**
	 * Sets the information and title to display
	 * @param newConfig the new config to set
	 */
	const setPanelConfig = React.useCallback((newConfig: InfoPanelConfig) => {
		setInfoPanelConfig(newConfig);
	}, []);

	/**
	 * Sets the section to open to when anchoring the panel to an info button within the app
	 * @param sectionToOpen the title of the info panel section to open
	 */
	const setOpenSection = React.useCallback((sectionToOpen: string) => {
		setOpenPanelSection(sectionToOpen);
	}, []);

	/**
	 * Sets the panel config to the default title and content
	 */
	const resetPanelConfig = React.useCallback(() => {
		setInfoPanelConfig(DefaultInfoPanelConfig);
	}, []);

	React.useEffect(() => {
		// Need to clear so that the user can infinitely toggle opening a particular section
		// Since state updates are batched, we can't just set the title and then set undefined immediately - it will only set undefined
		if (openPanelSection !== undefined) {
			setOpenPanelSection(undefined);
		}
	}, [openPanelSection]);

	return (
		<Styled.RoutedBody>
			<InfoPanelContext.Provider
				value={{
					...infoPanelConfig,
					setInfoConfig: setPanelConfig,
					resetInfoConfig: resetPanelConfig,
					openSection: openPanelSection,
					setOpenSection: setOpenSection,
					setOpenState: setInfoPanelOpen
				}}
			>
				<Styled.RoutedBodyContent isInfoPanelOpen={isInfoPanelOpen}>
					<Routes>
						<Route
							path="/"
							element={
								<Home
									onDisplayNotification={props.onDisplayNotification}
									onHideIntroMessage={props.onHideIntroMessage}
								/>
							}
						/>
						<Route
							path="vins/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"vehicleManagement"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedVehicleView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="policies/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"policyCreation"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedPolicyView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
													user={props.user}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="feature-names/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"featureNames"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedFeatureNames
													user={props.user}
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="matrix/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"documentation"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedTraceabilityMatrixView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="histograms/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"policyCreation"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedHistogramView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="sensors/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"policyCreation"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedSensorsView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="synthetics/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"policyCreation"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedSyntheticsView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="streams/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"userManagement"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedStreamsView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="vcr/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"vcrDashboard"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedVCRDashboard
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="users/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"userManagement"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedUserView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="user-profile/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"documentation"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedUserProfileView
													user={props.user}
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="groups/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"userManagement"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedGroupView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="vehicle-testing/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"vehicleManagement"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedVehicleTestingView
													requestManager={props.requestManager}
													onDisplayNotification={
														props.onDisplayNotification
													}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route
							path="support/*"
							element={
								<Suspense fallback={<LoadingLogo />}>
									<UserPermissionGuard
										user={props.user}
										scene={"documentation"}
										errorPage={<ErrorPage errorCode={"403"} />}
									>
										{() => {
											return (
												<RoutedSupportView
													user={props.user}
													requestManager={props.requestManager}
													isRequestingChange={true}
													onDisplayNotification={
														props.onDisplayNotification
													}
													onWaitForAccess={props.onWaitForAccess}
													region={props.region}
													onDirtyChanged={props.onDirtyChanged}
													onRequestAccess={props.onRequestAccess}
													handleDisplayNotification={
														props.handleDisplayNotification
													}
													onProdRequestAccess={props.onProdRequestAccess}
												/>
											);
										}}
									</UserPermissionGuard>
								</Suspense>
							}
						/>
						<Route path="*" element={<ErrorPage errorCode={"404"} />} />
					</Routes>
				</Styled.RoutedBodyContent>
				<InfoPanel isOpen={isInfoPanelOpen} setIsOpen={setInfoPanelOpen} />
			</InfoPanelContext.Provider>
		</Styled.RoutedBody>
	);
};
RoutedBody.displayName = COMPONENT_NAME;
