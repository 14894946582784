import { ExpandMore } from "@mui/icons-material";
import * as Material from "@mui/material";
import React from "react";
import { IconActionPair } from "../../utils/types";

/**
 * Props interface for this component.
 */
interface Props {
	/**
	 * the title of the accordion.
	 */
	title: React.ReactNode;
	/**
	 * Optionally renders the title as a node and not child of Typography
	 */
	isTitleNode?: boolean;
	/**
	 * Optional typography props for styling title
	 */
	titleProps?: Partial<Material.TypographyProps>;
	/**
	 * The default expanded state of this accordion.
	 */
	defaultExpanded?: boolean;
	/**
	 * A button left of the title.
	 */
	leftAction?: IconActionPair;
	/**
	 * Optional classname the dev can apply in addition to this components classname
	 */
	className?: string;
	/**
	 * Renders the content of the accordion.
	 */
	renderBody: () => React.ReactNode;
	/**
	 * Additional props to customize the accordion
	 */
	accordionProps?: Partial<Material.AccordionProps>;
}

const COMPONENT_NAME = "Accordion";

/**
 * Private implementation of a plan accordion.
 * @param props - component props.
 * @return an accordion.
 */
export const Accordion: React.FC<Props> = (props) => {
	const accordionClassNames = [COMPONENT_NAME];

	if (props.className) {
		accordionClassNames.push(props.className);
	}

	/**
	 * Renders a title with the appropriate parent
	 * @param children the title content
	 * @returns a rendered title
	 */
	const renderTitle = (children: React.JSX.Element) => {
		return props.isTitleNode ? (
			<>{children}</>
		) : (
			<Material.Typography color={"textSecondary"} {...props.titleProps}>
				{children}
			</Material.Typography>
		);
	};

	return (
		<Material.Accordion
			defaultExpanded={props.defaultExpanded}
			className={accordionClassNames.join(" ")}
			{...props.accordionProps}
		>
			<Material.AccordionSummary
				className={`${COMPONENT_NAME}__accordion-title`}
				expandIcon={<ExpandMore />}
			>
				{renderTitle(
					<>
						{props.leftAction &&
							(() => {
								const Icon = props.leftAction!.icon;
								return (
									<Material.IconButton
										onClick={(event) => {
											event.stopPropagation();
											props.leftAction!.onClick();
										}}
									>
										<Icon />
									</Material.IconButton>
								);
							})()}
						{props.title}
					</>
				)}
			</Material.AccordionSummary>
			<Material.AccordionDetails className={`${COMPONENT_NAME}__accordion-content`}>
				{props.renderBody()}
			</Material.AccordionDetails>
		</Material.Accordion>
	);
};
Accordion.displayName = COMPONENT_NAME;
