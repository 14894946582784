import { AppBar, Paper, styled } from "@mui/material";
import { bottomNavHeight } from "../../themes";

/**
 * The width of the info panel that's always visible in rem
 */
export const persistentInfoPanelWidth = 2.5;

/**
 * The width of the expanded portion of the panel in rem
 */
const expandedInfoPanelWidth = 17.5;

/**
 * The total combined width when expanded
 */
export const totalInfoPanelWidth = persistentInfoPanelWidth + expandedInfoPanelWidth;

/**
 * The padding for expanded pane items
 */
export const defaultPadding = "0 1rem";

/**
 * The entire info panel
 * @component Material.AppBar
 */
export const InfoPanel = styled(AppBar, { shouldForwardProp: (prop) => prop !== "isOpen" })<{
	isOpen: boolean;
}>(({ isOpen, theme }) => ({
	maxWidth: isOpen ? `${totalInfoPanelWidth}rem` : `${persistentInfoPanelWidth}rem`,
	height: "100vh",
	display: "flex",
	flexDirection: "row",
	// Adds bottom margin so content isn't hidden by bottom nav
	[theme.breakpoints.down("md")]: {
		paddingBottom: bottomNavHeight
	}
}));

/**
 * The persistent portion of the info panel
 * @component Material.Paper
 */
export const PersistentPanel = styled(Paper)({
	display: "flex",
	alignItems: "flex-start",
	width: `${persistentInfoPanelWidth}rem`
});

/**
 * The expanded portion of the panel
 * @component Material.Paper
 */
export const ExpandedPanel = styled(Paper)({
	flexGrow: 1,
	overflowY: "auto"
});

/**
 * The header of the expanded portion
 * @component Material.Paper
 */
export const Header = styled(Paper)({
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
	minHeight: "68.5px",
	padding: defaultPadding,
	position: "sticky",
	top: 0,
	zIndex: 1 // elevates above content
});

/**
 * A wrapper for accordion sections
 * @component div
 */
export const AccordionWrapper = styled("div")({
	"& .MuiPaper-root": {
		backgroundColor: "transparent" // Overrides elevation 0 background color
	},
	"& .MuiAccordionSummary-content": {
		margin: ".5rem 0"
	},
	"& .MuiButtonBase-root": {
		minHeight: "fit-content"
	}
});
