import { styled, Typography } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";
import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface StyledTextLinkProps extends LinkProps {
	children: React.ReactNode;
	variant?: TypographyProps["variant"];
}

interface StyledTextAnchorProps {
	children: React.ReactNode;
	variant?: TypographyProps["variant"];
	href?: string;
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

/**
 * Remove the default anchor tag styling on react-router "Link" component
 */
const StyledLink = styled(Link)({
	textDecoration: "none"
});

/**
 * Remove the default anchor tag styling
 */
const StyledAnchor = styled("a")({
	textDecoration: "none"
});

/**
 * Use the theme's primary color for the link color
 */
const StyledTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.hover,
	display: "inline",
	"&:hover": {
		color: theme.palette.primary.main
	}
}));

/**
 * A styled link that uses the theme's primary color for the link color.
 * Used anywhere developers want to use react router's "Link" component wrapped
 * around text, anchor tags, or any tool that has a default appearance to an
 * html link on a page.
 * @param param0
 * @returns
 */
const StyledTextLink = (props: StyledTextLinkProps | StyledTextAnchorProps) => {
	// checks if used for react-router, otherwise it's an anchor tag
	const isInternalLink = isStyledTextLinkProps(props); 
	// React-Router uses the "to" prop, while anchor tags use the "href" prop
	if (isInternalLink) {
		const { to, ...rest } = props as StyledTextLinkProps;
		return (
			<StyledLink to={to} {...rest}>
				<StyledTypography variant={rest.variant}>{rest.children}</StyledTypography>
			</StyledLink>
		);
	} else {
		const { href, ...rest } = props as StyledTextAnchorProps;
		return (
			<StyledAnchor href={href} {...rest}>
				<StyledTypography variant={rest.variant}>{rest.children}</StyledTypography>
			</StyledAnchor>
		);
	}
};

/**
 * Type guard to check if the props are for a react-router
 * "Link" component, as identified by the "to" prop.
 * @param props
 * @returns
 */
function isStyledTextLinkProps(
	props: StyledTextLinkProps | StyledTextAnchorProps
): props is StyledTextLinkProps {
	return "to" in props;
}

export default StyledTextLink;
