import * as Material from "@mui/material";
import React from "react";
import { $enum } from "ts-enum-util";
import * as ApiModelTypes from "../../api/apiModelTypes";
import { LoadingMask } from "../../components/LoadingMask";
import { IS_PRODUCTION_ENVIRONMENT } from "../../constants";
import { darkTheme, standardTheme } from "../../themes";
import { THEME_PREFERENCE } from "../../utils/localStorage";
import { Regions } from "../../utils/regionUtils";
import { Roles } from "../../utils/roleUtils";
import AccessRequestView from "./AccessRequestView";
import { InteractiveAccessRequestForm } from "./InteractiveAccessRequestForm";
import * as Styled from "./landingPageStyles";

const COMPONENT_NAME = "LandingPage";

/**
 * Props interface for this component.
 */
export interface Props {
	/**
	 * Is the "intention" string while user has requested access but is waiting for it to be approved.
	 */
	waitingForAccess?: string | null;
	/**
	 * Called when the user is ready to request access.
	 * @param selectedRoles - the role they wish to assume.
	 * @param selectedRegions - the region(s) for which they need access to.
	 * @param businessJustification - the business justification.
	 */
	onRequestAccess(
		userGroup: string,
		selectedRoles: Roles[],
		selectedRegions: Regions[],
		businessJustification: string
	): Promise<any>;
	/**
	 * Production-specific version of onRequestAccess
	 * @param userGroup - the organization the user belongs to.
	 * @param selectedRegions - the region(s) for which they need access to
	 * @param businessJustification - the business justification.
	 */
	onProdRequestAccess(
		userGroup: string,
		selectedRegions: Regions[],
		businessJustification: string,
		isReqArea51: boolean
	): Promise<any>;
	/**
	 * Called when it is determined that the user must wait for access.
	 * @param intention - the intention they wish to assume.
	 */
	onWaitForAccess(intention: string): void;
	/**
	 * Flag to conditionally change verbiage of view and webhook if user is requesting change of permissions
	 */
	isRequestingChange: boolean;
	/**
	 * The currently logged in user
	 */
	user: ApiModelTypes.Me;
}

/**
 * The website landing page for ungrouped users.
 * @param props - the component props.
 * @returns The rendered component.
 */
export const LandingPage: React.FC<Props> = (props) => {
	const localStorage = window.localStorage;
	const [isWaitingForAccess, setIsWaitingForAccess] = React.useState<boolean>(
		!!props.waitingForAccess || false
	);
	const [existingPermissions, setExistingPermissions] = React.useState<string[]>([]);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

	/**
	 * Identify all groups belonging to the user
	 * necessary for conditionally rendering
	 * UI components within this view
	 */
	React.useEffect(() => {
		if (props.user.groups && props.user.groups.length > 0) {
			// Array of regions represented as string, probably ["NA", "EU"]
			const supportedRegions: string[] = $enum(Regions).map((region) => region.toString());
			// filter user groups for group that includes a region prefixed with a hyphen e.g. admin-na
			// user groups includes a group that looks like "us-east-XXXXXXXX_StellantisSSO-PingFederate" we don't care about
			const userGroups: string[] = props.user.groups.filter((group) => {
				return supportedRegions.some((region) =>
					group.toUpperCase().includes("-" + region.toUpperCase())
				);
			});
			setExistingPermissions(userGroups);
		}
	}, [props.user.groups]);

	return (
		<Material.ThemeProvider
			theme={localStorage.getItem(THEME_PREFERENCE) === "DARK" ? darkTheme : standardTheme}
		>
			<Material.Fade in={true}>
				<Styled.LandingPage>
					<LoadingMask isOpen={isSubmitting} />
					{!isWaitingForAccess || props.isRequestingChange ? (
						<Material.Slide direction="right" in={true} mountOnEnter unmountOnExit>
							<Material.Container>
								{!props.isRequestingChange && (
									<Material.Typography
										variant="h2"
										gutterBottom={true}
										align="center"
									>
										Welcome to the ADA Web Portal
									</Material.Typography>
								)}
								<Styled.Paper elevation={4}>
									<Styled.AccessRequestFormTitle>
										<Material.Typography
											variant="h4"
											gutterBottom={true}
											align="center"
										>
											{props.isRequestingChange
												? "Requesting Change of Permissions"
												: "Requesting ADA Portal Access"}
										</Material.Typography>
									</Styled.AccessRequestFormTitle>
									{props.isRequestingChange && existingPermissions.length > 0 && (
										<Material.Alert severity="info">
											You currently have the
											{existingPermissions.length > 1 ? " roles" : " role"}:
											{" " + existingPermissions.join(", ")}
										</Material.Alert>
									)}
									{IS_PRODUCTION_ENVIRONMENT && !props.isRequestingChange ? (
										<InteractiveAccessRequestForm
											onSubmit={(
												userGroup: string,
												selectedRegions: Regions[],
												businessJustification: string,
												isReqArea51: boolean
											) => {
												setIsSubmitting(true);
												props
													.onProdRequestAccess(
														userGroup,
														selectedRegions,
														businessJustification,
														isReqArea51
													)
													.then(() => {
														setIsSubmitting(false);
														setIsWaitingForAccess(true);
													})
													.finally(() => {
														setIsSubmitting(false);
													});
											}}
										/>
									) : (
										<AccessRequestView
											onSubmit={(
												userGroup: string,
												selectedRoles: Roles[],
												selectedRegions: Regions[],
												businessJustification: string
											) => {
												setIsSubmitting(true);
												props
													.onRequestAccess(
														userGroup,
														selectedRoles,
														selectedRegions,
														businessJustification
													)
													.then(() => {
														if (!props.isRequestingChange) {
															setIsWaitingForAccess(true);
														}
														setIsSubmitting(false);
													})
													.finally(() => {
														setIsSubmitting(false);
													});
											}}
											isRequestingChange={props.isRequestingChange}
											existingPermissions={existingPermissions}
										/>
									)}
								</Styled.Paper>
							</Material.Container>
						</Material.Slide>
					) : (
						<Material.Slide
							direction="left"
							in={true}
							style={{ transitionDelay: isWaitingForAccess ? "500ms" : "0ms" }}
							onEntered={() => props.onWaitForAccess(props.waitingForAccess!)}
						>
							<Material.Container>
								<Styled.Paper>
									<Material.Typography
										variant="h4"
										gutterBottom={true}
										align="center"
									>
										Your Request Has Been Submitted
									</Material.Typography>
									<Material.Typography
										variant="h5"
										gutterBottom={true}
										align="center"
									>
										Please check back later. Your request is currently under
										review, and once it's processed, you'll be able to log in to
										the ADA portal.
									</Material.Typography>
								</Styled.Paper>
							</Material.Container>
						</Material.Slide>
					)}
				</Styled.LandingPage>
			</Material.Fade>
		</Material.ThemeProvider>
	);
};
LandingPage.displayName = COMPONENT_NAME;
